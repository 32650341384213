import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router-dom';
import { bgBlur } from 'src/theme/css';
import Iconify from 'src/components/iconify';
import { HEADER } from './config-layout';

// ----------------------------------------------------------------------

export default function Header({
  visimisiViewRef,
  blogViewRef,
  wwdViewRef,
  subsidiViewRef,
  contactViewRef,
}) {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigationItems = [
    { label: 'Vision & Mission', scrollRef: visimisiViewRef, section: 'vision' },
    { label: 'What We Do', scrollRef: wwdViewRef, section: 'wwd' },
    { label: 'Solution', scrollRef: subsidiViewRef, section: 'subsidi' },
    { label: 'Blog', scrollRef: blogViewRef, section: 'blog' },
  ];

  const handleScrollToSection = (scrollRef, section) => {
    if (!scrollRef || !scrollRef.current) {
      navigate('/', { state: { section } });
    } else {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/', { state: { section: 'top' } });
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const renderContent = (
    <>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          width: '100%',
          pt: isMobile ? 1 : 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            paddingX: 2,
            mt: isMobile ? 3 : -1,
            flexWrap: 'nowrap',
            position: 'relative',
          }}
        >
          {isMobile || isTablet ? (
            <>
              <Box
                component="img"
                src="/assets/logo.png"
                alt="Logo"
                sx={{
                  height: 40,
                  mr: 1,
                  cursor: 'pointer',
                  position: 'relative',
                  top: isMobile ? '-20px' : '-5px',
                }}
                onClick={handleLogoClick}
              />
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  position: 'absolute',
                  top: isMobile ? '-20px' : '10px',
                  right: '10px',
                }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              />
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                sx={{ '& .MuiDrawer-paper': { width: '250px' } }}
              >
                <IconButton onClick={handleDrawerToggle} sx={{ m: 1 }}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
                <Divider />
                <List>
                  {navigationItems.map(({ label, scrollRef, section }) => (
                    <ListItem
                      button
                      key={label}
                      onClick={() => handleScrollToSection(scrollRef, section)}
                    >
                      <ListItemText primary={label} />
                    </ListItem>
                  ))}
                  {isMobile && (
                    <ListItem
                      button
                      onClick={() => handleScrollToSection(contactViewRef, 'contact')}
                    >
                      <ListItemText primary="Contact Us" />
                    </ListItem>
                  )}
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box
                    component="img"
                    src="/assets/logo.png"
                    alt="Logo"
                    sx={{ height: 100, cursor: 'pointer', mt: '-5px' }}
                    onClick={handleLogoClick}
                  />

                  {navigationItems.map(({ label, scrollRef, section }) => (
                    <Button
                      key={label}
                      onClick={() => handleScrollToSection(scrollRef, section)}
                      sx={{ color: 'black' }}
                    >
                      {label}
                    </Button>
                  ))}
                </Stack>

                <Button
                  variant="outlined"
                  onClick={() => handleScrollToSection(contactViewRef, 'contact')}
                  sx={{ minWidth: '120px', height: '36px', mt: '-5px' }}
                >
                  Contact Us
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER?.H_MOBILE || 56,
        zIndex: theme.zIndex.appBar + 1,
        position: 'fixed',
        top: 0,
        width: '100%',
        paddingTop: '1.5vw',
        paddingBottom: '1.5vw',
        backgroundColor: '#222222 !important',
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          height: HEADER?.H_DESKTOP || 64,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          minHeight: { xs: '48px', sm: '56px' },
          alignItems: 'flex-start',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  visimisiViewRef: PropTypes.object,
  wwdViewRef: PropTypes.object,
  subsidiViewRef: PropTypes.object,
  blogViewRef: PropTypes.object,
  contactViewRef: PropTypes.object,
};
