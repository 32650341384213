import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';

export const IndexPage = lazy(() => import('src/pages/app'));
export const BlogPage = lazy(() => import('src/pages/blog'));
export const CareerPage = lazy(() => import('src/pages/careers'));
export const ClientPage = lazy(() => import('src/pages/clients'));
export const ContactPage = lazy(() => import('src/pages/contact'));
export const SubsidiariesPage = lazy(() => import('src/pages/subsidiaries'));
export const VisimisiPage = lazy(() => import('src/pages/visimisi'));
export const WhatwedoPage = lazy(() => import('src/pages/whatwedo'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));
export const TransformasiDigitalPage = lazy(() => import('src/pages/transformasi-digital'));
export const CoreBankingPage = lazy(() => import('src/pages/core-banking'));
export const BankDigitalPage = lazy(() => import('src/pages/bank-digital'));
export const Blog1 = lazy(() => import('src/pages/blog-1page'));
export const Blog2 = lazy(() => import('src/pages/blog-2page'));
export const Blog3 = lazy(() => import('src/pages/blog-3page'));
export const Blog4 = lazy(() => import('src/pages/blog-4page'));
export const Blog5 = lazy(() => import('src/pages/blog-5page'));
export const Blog6 = lazy(() => import('src/pages/blog-6page'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'blog', element: <BlogPage /> },
        { path: 'career', element: <CareerPage /> },
        { path: 'client', element: <ClientPage /> },
        { path: 'cp', element: <ContactPage /> },
        { path: 'ss', element: <SubsidiariesPage /> },
        { path: 'vm', element: <VisimisiPage /> },
        { path: 'wwd', element: <WhatwedoPage /> },
        { path: 'transformasi-digital', element: <TransformasiDigitalPage /> },
        { path: 'core-banking', element: <CoreBankingPage /> },
        { path: 'digital-bank', element: <BankDigitalPage /> },
        { path: 'blockchain-keuangan', element: <Blog1 /> },
        { path: 'ai-machine-learning-fintech', element: <Blog2 /> },
        { path: 'startup-fintech-disrupsi', element: <Blog3 /> },
        { path: 'keamanan-siber-fintech', element: <Blog4 /> },
        { path: 'open-banking-keuangan-personal', element: <Blog5 /> },
        { path: 'pembayaran-digital-pasar-berkembang', element: <Blog6 /> },
      ],
    },
    // {
    //   path: "login",
    //   element: <LoginPage />,
    // },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
