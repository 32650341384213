import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Vision & Mission',
    path: '/vm',
    icon: icon('ic_analytics'),
  },
  {
    title: 'What We Do',
    path: '/wwd',
    icon: icon('ic_user'),
  },
  {
    title: 'Subsidiaries',
    path: '/ss',
    icon: icon('ic_cart'),
  },
  {
    title: 'Clients',
    path: '/client',
    icon: icon('ic_blog'),
  },
  {
    title: 'Blog',
    path: '/blog',
    icon: icon('ic_lock'),
  },
  {
    title: 'Careers',
    path: '/career',
  },
  {
    title: 'Contact Us',
    path: '/cp',
  },
  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  },
];

export default navConfig;
